@include component('header') {
	padding:10px;
	background-color:$white;
	box-shadow:0 2px 4px 0 rgba($black, .3);
	position:relative;
	z-index:999;
	@include element('brand') {
		max-width:130px;
		position:absolute;
		z-index:999;
		img{
			width:100%;
			height:auto;
		}
	}
	@include element('btn') {
		display:none;
		float: right;
		width:60px;
		height:60px;
		font-size:30px;
		position:relative;
		text-align:center;
		i{
			line-height:21px;
			@extend .u-center;
		}
		@include max-screen(768px) {
			display:block;
		}
	}
}
