// Colors
$white:#FFF;
$black:#000;
$color_primary:#B81215;
$color_secundary:#212121;
$color_third:#9B9B9B;
$color_text:$black;
$color_bg:#ffffff;

// fonts
$base_size:16px;
$font_base:'Roboto Slab', helvetica, arial, sans-serif;

// Common
$transition-duration: .3s !default;

// Margins & Paddings
$numbers: 0, 10, 20, 40, 60;
$sides: t top,r right,b bottom,l left;
