@include component('bottom') {
	background-color:#6E7473;
	text-align:center;
	padding:12px;
	@include element('text') {
		color:$white;
		font-size:12px;
		font-weight:400;
		line-height:100%;
		margin: 0 auto;
		a{
			color:$white;
		}
	}
}
