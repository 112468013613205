// Text
@include utility('text_center') {
	text-align: center;
}
@include utility('text_left') {
  text-align: left;
}
@include utility('text_right') {
  text-align: right;
}
@include utility('pull_right') {
	float: right;
}
@include utility('pull_left') {
	float: left;
}
// Boxes
@include utility('no_gutter') {
	@include no-gutter;
}
@include utility('vertical_center') {
	display: table-cell;
	vertical-align: middle;
}
@include utility('no_margin') {
	margin:0;
}
@include utility('clearfix') {
	display:block;
	width:100%;
	clear:both;
}

// Positions
@include utility('cover') {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
@include utility('center') {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	margin-left: auto;
	margin-right:auto;
	left:0;
	right:0;
}
@include utility('align') {
	font-size:0;
	&>div{
		float:none;
		display:inline-block;
		vertical-align:middle;
	}
}

// Images
@include utility('img_disabled') {
	@include img-disabled;
}
@include utility('img_responsive') {
	display:block;
	max-width:100%;
	height:auto;
}

// visibility
@include utility('hidden_mobile') {
	@include max-screen(991px) {
		display:none!important;
	}
}
@include utility('hidden_desktop') {
	@include min-screen(992px) {
		display:none!important;
	}
}

// line
@include utility('line') {
	@include modifier('default') {
		margin:10px 0;
	}
}

// Border
@include utility('no_border') {
	border:none!important;
}


// padding
@include utility('pad15') {
	padding:15px;
}

// Margin
@include utility('mbm20') {
	@include max-screen(768px) {
		margin-bottom:20px;
	}
}
.u-mb60{
	@include max-screen(768px) {
		margin-bottom:30px;
	}
}

@include utility('float_btn') {
	width:130px;
	height:130px;
	position:absolute;
	right:100px;
	bottom:60px;
	z-index:900;
	background-image:url('../img/catalogo.png');
	background-repeat:no-repeat;
	background-position:center;
	background-size:cover;
}

@include utility('stick') {
	position:fixed;
	top:0;
	left:0;
	width:100%;
	z-index:999;
	& + main{
		padding-top:100px;
	}
}

@include utility('second_bg') {
	background-color:#F4F4F4;
}

@include utility('bgbox') {
	background-color:$white;
	border-radius:4px;
}



.pn-ProductNav {
    /* Make this scrollable when needed */
    overflow-x: auto;
    /* We don't want vertical scrolling */
    overflow-y: hidden;
    /* For WebKit implementations, provide inertia scrolling */
    -webkit-overflow-scrolling: touch;
    /* We don't want internal inline elements to wrap */
    white-space: nowrap;
    /* If JS present, let's hide the default scrollbar */
    .js & {
        /* Make an auto-hiding scroller for the 3 people using a IE */
        -ms-overflow-style: -ms-autohiding-scrollbar;
        /* Remove the default scrollbar for WebKit implementations */
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

// .pn-ProductNav_Contents {
// 	float: left;
// }

// .pn-ProductNav_Link {
// 	text-decoration: none;
// 	color: #888;
// 	font-size: 1.2em;
// 	font-family: -apple-system, sans-serif;
// 	display: inline-flex;
// 	align-items: center;
// 	min-height: 44px;
// 	& + & {
// 		margin-left: 11px;
// 		padding-left: 11px;
// 		border-left: 1px solid #eee;
// 	}
// 	&[aria-selected="true"] {
// 		color: #111;
// 	}
// }
