@include component('top') {
	background-color:#212121;
	text-align:center;
	padding:7px;
	@include element('text') {
		color:$white;
		font-size:12px;
		font-weight:400;
		line-height:100%;
		margin: 0 auto;
		a{
			color:$white;
		}
	}
}
