@include object('carrusel') {
	@include element('item') {
		text-align:center;
		min-height:300px;
	}
	@include element('icon') {
		font-size:70px;
		line-height:100%;
		&:before{
			line-height:100%;
		}
	}
	@include element('title') {
		font-size:18px;
		position:relative;
		margin-bottom:0;
		padding:7px;
		&:before{
			content:'';
			width:50px;
			height:2px;
			position:absolute;
			left:0;
			right:0;
			margin-left:auto;
			margin-right:auto;
			bottom:0;
			background-color:#B81215;
		}
	}
	@include element('text') {
		color:#6E7473;
		font-size:12px;
		max-width:180px;
		margin:20px auto;
	}
	@include element('btn') {
		background-color:$color_primary;
		color:$white;
		font-size:12px;
		border-radius: 5px;
		line-height:100%;
		padding:7px 16px;
		text-transform:uppercase;
		&:hover{
			background-color:darken($color_primary, 13%);
			color:$white;
			text-decoration:none;
		}
	}
	.slick-arrow{
		background-color:#6E7473;
		width:30px;
		height:30px;
		border-radius:30px;
		&.slick-prev,&.slick-next{
			&:before{
				font-family: 'aleuros';
			}
		}
		&.slick-prev{
			&:before{
				content:'\68'
			}
			@include max-screen(992px) {
				left:0;
			}
		}
		&.slick-next{
			&:before{
				content:'\67'
			}
			@include max-screen(992px) {
				right:0;
			}
		}
		&:hover,&:focus{
			background-color:$color_primary;
		}
	}
}
