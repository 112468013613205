@include component('hero') {
	background-color:$color_primary;
	position:relative;
	height:600px;
	@include modifier('home') {
		background-image:url('../img/hero-home.png');
		background-position:right center;
		background-repeat:no-repeat;
		@include max-screen(768px) {
			padding:80px 0;
			// background-image:none;
			background-size:cover;
			height:initial;
			.u-center{
				position:relative;
				transform:none;
			}
		}
		@include max-screen(480px) {
			background-position: right 25% bottom;
			background-size: auto 70%;
			.u-float_btn{
				right:20px;
				bottom:20px;
			}
		}
	}
	@include modifier('cat') {
		background-image:url('../img/cat-bg.jpg');
		background-position:right top;
		background-repeat:repeat-x;
		height:490px;
		background-color:#F4F4F4;
		.u-center{
			margin-top:-30px;
		}
		.c-hero__title{
			font-weight:700;
		}
		@include max-screen(768px) {
			padding:70px 0;
			// background-image:none;
			background-color:#B81216;
			background-size:cover;
			height:initial;
			.u-center{
				margin-top:0;
				position:relative;
				transform:none;
			}
		}
		@include max-screen(480px) {
			background-position: right  top;
			background-size: auto 70%;
		}
	}
	@include element('title') {
		color:$white;
		font-size:30px;
		font-weight:400;
		line-height:40px;
		margin-bottom:15px;
		padding-bottom:15px;
		border-bottom:solid 1px rgba($white, .5);
		text-transform:uppercase;
		strong{
			font-weight:700;
		}
		@include max-screen(768px) {
			font-size:18px;
			line-height:100%;
			word-wrap: break-word;
		}
	}
	@include element('subtitle') {
		color:$white;
		font-size:16px;
		line-height:22px;
		font-weight:400;
	}
	@include element('btn') {
		border:solid 1px $white;
		border-radius:5px;
		color:$white;
		display:inline-block;
		padding:12px 28px;
		text-transform:uppercase;
		&:hover{
			color:$white;
			text-decoration:none;
			background-color:darken($color_primary, 15%);
		}
		@include max-screen(768px) {
			padding:12px 12px;
		}
	}
	@include element('referent') {
		position:absolute;
		right:10%;
		top:-50px;
		@include max-screen(992px) {
			right:20px;
		}
		@include max-screen(768px) {
			max-width:220px;
			img{
				width:100%;
			}
		}
		@include max-screen(560px) {
			max-width:160px;
			top: 0;
			img{
				width:100%;
			}
		}
		@include max-screen(480px) {
			top:60px;
			right:15px;
			max-width:140px;
		}
	}

}
