@include component('form') {
	@include element('field') {
		display:table;
		width:100%;
		padding:10px;
		text-align:left;
	}
	@include element('label') {
		display:table-cell;
		vertical-align:middle;
		width:100px;
	}
	@include element('input') {
		display:table-cell;
		vertical-align:middle;
		width:100%;
		border:solid 1px #D3D3D3;
		border-radius:3px;
		padding:5px;
	}
	@include element('textarea') {
		vertical-align:middle;
		display:table-cell;
		width:100%;
		height:80px;
		border:solid 1px #D3D3D3;
		border-radius:3px;
		padding:5px;
	}
}
