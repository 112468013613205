@include object('title') {
	text-align:center;
	font-size:25px;
	padding:10px;
	position:relative;
	span{
		color:#9B9B9B;
		font-weight:400;
		margin-right:2px;
		margin-left:2px;
	}
	strong{
		color:$color_primary;
		font-weight:700;
		margin-right:2px;
		margin-left:2px;
	}
	&:before{
		content:'';
		width:100px;
		height:2px;
		position:absolute;
		left:0;
		right:0;
		margin-left:auto;
		margin-right:auto;
		bottom:0;
		background-color:$color_primary;
	}
}
