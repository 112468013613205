@include component('layer') {
	background-color:rgba(#000, .7);
	position:fixed;
	width:100%;
	height:100%;
	z-index:999;
	top:0;
	left:0;
	display:none;
}
@include component('modal') {
	display:none;
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	margin-left: auto;
	margin-right:auto;
	left:0;
	right:0;
	z-index:1000;
	padding:15px;
	@include modifier('big') {
		max-width:900px;
	}
	@include modifier('small') {
		max-width:450px;
	}
	@include modifier('medium') {
		max-width:550px;
	}
	@include element('text') {
		font-size: 15px;
		line-height:20px;
		color: #000000;
	}
	@include element('box') {
		position:relative;
		background-color:$white;
		border-radius:2px;
		display:block;
		margin:0 auto;
		padding:15px;
		box-shadow: 0px 2px 4px 0px rgba(#191919, .1);
		@include max-screen(320px) {
			max-height:400px;
			overflow:hidden;
			overflow-y: auto;
		}
	}
	@include element('content') {

	}
	@include element('close') {
		position:absolute;
		top:-30px;
		right:0;
		width:15px;
		height:15px;
		z-index:1000;
		color:$white;
		text-indent:-9999px;
		overflow:hidden;
		background-image:url('../img/icon-close-white.png');
		background-repeat:no-repeat;
		background-size: cover;
		&:hover{
			text-decoration:none;
			opacity:.6;
		}
	}
}
