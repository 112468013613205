@include object('card') {
	background-color:$white;
	border-radius:4px;
	padding:15px;
	@include element('picture') {
		height:220px;
		width:100%;
		margin-bottom:20px;
		overflow:hidden;
		img{
			width:auto;
			margin-left:auto;
			display:block;
			margin-right:auto;
			height:100%;
			@include img-disabled;

		}
	}
	@include element('cat') {
		font-size:12px;
		color:rgba($color_secundary, .5);
	}
	@include element('title') {
		font-size:18px;
		font-weight:400;
		line-height:22px;
		min-height:45px;
		color:$color_secundary;
	}
	@include max-screen(768px) {
		margin-bottom:20px;
	}
}



