@include object('product') {
	margin-bottom:20px;
	@include element('figure') {
		max-width:220px;
		width:100%;
		height:220px;
		margin-bottom:15px;
		display:block;
		margin-left:auto;
		margin-right:auto;
	}
	@include element('title') {
		font-size:18px;
		color:#212121;
		line-height:22px;
		display:block;
		margin-bottom:0;
		font-weight:500;
		text-align:center;
	}
}
