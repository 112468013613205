html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-size:$base_size;
	-webkit-tap-highlight-color:rgba(0,0,0,0);
}

body {
	font-family:$font_base;
	font-size:$base_size;
	color:$color_text;
	background-color:$color_bg;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 0;
	// margin-bottom: .5rem;
}

p {
	margin-top: 0;
	// margin-bottom: 1rem;
}

ul, ol, dl {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
}

a,
button,
input,
select {
	transition-property: transform, background, background-color, border-color, color;
	transition-duration: $transition-duration;
}

textarea {
	resize: vertical;
}

a {
	color:$color_text;
	text-decoration: none;
	&:hover{
		color:$color_primary;
		text-decoration: underline;
	}
	&:focus {
		outline:none;
	}
}

img {
	vertical-align: middle;
}

a,
area,
button,
[role="button"],
input,
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

[role="button"] {
	cursor: pointer;
}

[hidden] {
	display: none !important;
}

.js-getImage{
	position:relative;
}
.js-image{
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat !important;
	overflow: hidden;
	@extend .u-cover;
	img{
		visibility: hidden;
	}
	&:hover{
		// animation-duration:5s;
		// animation-fill-mode: both;
		// animation-name: bgMove;
	}
}

hr{
	opacity:.4;
	margin:30px auto;
}
