@charset "UTF-8";

@font-face {
	font-family: "aleuros";
	src:url("../fonts/icons/aleuros.eot");
	src:url("../fonts/icons/aleuros.eot?#iefix") format("embedded-opentype"),
		url("../fonts/icons/aleuros.woff") format("woff"),
		url("../fonts/icons/aleuros.ttf") format("truetype"),
		url("../fonts/icons/aleuros.svg#aleuros") format("svg");
	font-weight: normal;
	font-style: normal;

}

[data-icon]:before {
	font-family: "aleuros" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="ico-"]:before,
[class*=" ico-"]:before {
	font-family: "aleuros" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ico-bandas:before {
	content: "\61";
}
.ico-folclorico:before {
	content: "\62";
}
.ico-institucional:before {
	content: "\63";
}
.ico-promocionales:before {
	content: "\64";
}
.ico-uniformes:before {
	content: "\65";
}
.ico-vestuario:before {
	content: "\66";
}
.ico-angle-right:before {
	content: "\67";
}
.ico-angle-left:before {
	content: "\68";
}
.ico-bars:before {
	content: "\69";
}
.ico-thumbs-up:before {
	content: "\6a";
}
.ico-thumbs-down:before {
	content: "\6b";
}
.ico-times:before {
	content: "\6c";
}
.ico-check:before {
	content: "\6d";
}
