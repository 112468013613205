@include component('about') {
	background-color:#212121;
	color:$white;
	padding-top:60px;
	padding-bottom:60px;
	@include element('text') {
		text-align:center;
		font-size:20px;
		p{
			margin-bottom:30px;
			line-height:26px;
			@include max-screen(768px) {
				line-height:20px;
			}
		}
		@include max-screen(768px) {
			font-size:16px;
		}
	}
}
