@include component('tabs') {
	display:block;
	@include element('header') {}
	@include element('nav') {}
	@include element('tab') {
		display:inline-block;
	}
	@include element('link') {
		display:block;
		padding:10px 35px;
		color:#9F9F9F;
		font-size:20px;
		text-transform:capitalize;
		@include state('active') {
			color:$color_primary;
			background-color:$white;
			border-radius:4px 4px 0 0;
		}
		&:hover{
			color:$color_primary;
			text-decoration:none;
		}
		&:focus{
			outline:none;
		}
	}
	@include element('container') {
		background-color:#fff;
	}
	@include element('content') {
		padding:30px;
		display:none;
		@include state('active') {
			display:block;
		}
	}
}
