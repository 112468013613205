@include object('button') {
	border:none;
	background-color: #B81215;
	color: #fff;
	font-size: 13px;
	border-radius: 5px;
	line-height: 100%;
	padding: 9px 16px;
	text-transform: uppercase;
	&:hover{
		background-color:darken(#B81215, 10%);
		color:$white;
		text-decoration:none;
	}
	&:focus{
		outline:none;
	}
}
