@include component('nav') {

	@include element('items') {
		text-align:right;
		@include max-screen(768px) {
			text-align:center;
			overflow-y:auto;
			max-height: 370px;
		}
	}
	@include element('item') {
		border-left:solid 1px #F1F1F1;
		display:inline-block;
		position:relative;
		&:first-child{
			border:none;
		}
		@include max-screen(768px) {
			display:block;
		}
	}
	@include element('link') {
		padding:20px;
		display:block;
		color:#93918F;
		text-transform: capitalize;
		&:hover{
			text-decoration:none;
		}
		@include max-screen(768px) {
			display:inline-block;
		}
	}
	@include max-screen(768px) {
		display:none;
	}
	@include state('active') {
		@include max-screen(768px) {
			display:block;
			position:fixed;
			z-index:998;
			top:106px;
			left:0;
			width:100%;
			background-color:$white;
		}
	}
	@include element('subnav') {
		display:none;
		position: absolute;
		background-color:$white;
		width:250px;
		top:100%;
		left:50%;
		transform:translateX(-50%);
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.3);
		@include max-screen(768px) {
			position:relative;
			transform:none;
			left:inherit;
			width: 100%;
			box-shadow:none;
			background-color:darken($white, 5%);
		}
		& > .c-nav__link{
			pointer-events: none;
		}
	}
	#subnav{
		&:hover{
			.c-nav__subnav{
				display:block;
				.c-nav__item{
					border-left:none;
					width:100%;
					text-align:center;
					.c-nav__link{
						padding:15px;
					}
				}
			}
		}
	}
}
